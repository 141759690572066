html, body { height: 100% }

body {
  margin: 0;
  font-family: 'Alegreya';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
  font-size: 24pt;
}

p, p.MuiTypography-root {
  padding: 0;
  margin: 4px 4px 16px;
}

@media all and (max-width: 600px) {
  #root {
    justify-content: start;
  }
}